import styled from "styled-components";
import { BP, Colors, Rem } from "../../commons/Theme";

export const StyledBusinessCalculator = styled.div`
  background-color: ${Colors.greenlight};
  text-align: center;
  border-radius: ${Rem(0)};
  color: ${Colors.grayDark};

  @media (${BP.ipad}) {
    border-radius: ${Rem(30)};
  }
`;

export const StyledBusinessCalculatorContent = styled.div`
  padding: ${Rem(20)} ${Rem(10)} ${Rem(40)} ${Rem(10)};

  @media (${BP.ipad}) {
    padding: ${Rem(40)} ${Rem(100)};
  }
`;

export const StyledBusinessCalculatorTitle = styled.h2`
  font-size: ${Rem(25)};
  font-weight: bold;
  color: ${Colors.greenPrimary};

  @media (${BP.ipad}) {
    font-size: ${Rem(50)};
  }
`;

export const StyledBusinessCalculatorSubtitle = styled.h3`
  font-size: ${Rem(14)};
  font-weight: normal;
  padding-bottom: ${Rem(20)};

  @media (${BP.ipad}) {
    font-size: ${Rem(24)};
  }
`;

export const StyledBusinessCalculatorInputs = styled.div`
  display: flex;
  justify-content: center;
  gap: ${Rem(50)};
  padding-bottom: ${Rem(20)};
`;

export const StyledBusinessCalculatorInputsTitle = styled.h4`
  font-size: ${Rem(14)};
  color: ${Colors.greenPrimary};
  font-weight: normal;
  padding-bottom: ${Rem(20)};
  max-width: ${Rem(290)};
  margin: 0 auto;

  @media (${BP.ipad}) {
    font-size: ${Rem(18)};
  }
`;

export const StyledBusinessCalculatorInputsItem = styled.div`
`;

export const StyledBusinessCalculatorInputsItemIcon = styled.div`

`;

export const StyledBusinessCalculatorInputsItemName = styled.div`
font-size: ${Rem(16)};
  font-weight: 700;
  padding: ${Rem(10)} 0;

  @media (${BP.ipad}) {
    font-size: ${Rem(30)};
  }
`;

export const StyledBusinessCalculatorInputsItemSubtitle = styled.div`
font-size: ${Rem(10)};
  font-weight: 450;
  color: ${Colors.greenPrimary};
  padding: ${Rem(10)} 0;

  @media (${BP.ipad}) {
    font-size: ${Rem(12)};
  }
`;

export const StyledBusinessCalculatorResults = styled.div`
  flex-direction: column;
  width: fit-content;
  margin: 0 auto;

  @media (${BP.ipad}) {
    flex-direction: row;
  }
`;

export const StyledBusinessCalculatorResultsItem = styled.div`
  min-width: ${Rem(120)};
  margin: ${Rem(15)};

  @media (${BP.ipad}) {
    margin: ${Rem(15)};
  }
`;

export const StyledBusinessCalculatorResultsItemLabel = styled.div`
  font-size: ${Rem(12)};
`;

export const StyledBusinessCalculatorResultsItemValue = styled.div`
  font-size: ${Rem(20)};

  @media (${BP.ipad}) {
    font-size: ${Rem(30)};
  }
`;

export const StyledBusinessCalculatorDivider = styled.div`
  margin: ${({ hideMobile }) => hideMobile ? 0 : Rem(15)} 0;
  width: ${Rem(3)};
  border-radius: ${Rem(3)};
  background-color: ${Colors.greenlight};
`;

export const StyledBusinessCalculatorButton = styled.div`
  position: absolute;
  bottom: ${Rem(-17)};
  width: 100%;
`;
